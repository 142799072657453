@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap);
* {
  font-family: "Quicksand", sans-serif;
  margin: 0;
}

/* App */
.App {
  display: grid;
  grid-template-rows: 10% auto 10%;
}

.back-to-home-content {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.back-to-home-link {
  text-decoration: none;
  color: #fff;
}

.back-to-home-button {
  border-color: transparent;
  cursor: pointer;
  font-family: Quicksand, sans-serif;
  background-color: #9863fc;
  color: #fff;
  padding-top: 4%;
  padding-bottom: 4%;
  font-size: 1rem;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.mobile_view {
  display: none;
}

/* Barre de navigation */
.navbar {
  display: flex;
  width: 100%;
  background: #f9f9f9;
  transition: 0.6s;
  z-index: 11;
}

.mobile_navbar,
.overlay_background,
.mobile_footer {
  display: none;
}

.navbar .navbar_logo img,
.footer .footer_logo img {
  max-height: 25px;
  max-width: 110px;
  padding: 10px;
}
.navbar .navbar_logo img {
  margin-top: 4%;
  margin-left: 50px;
}

.footer .footer_logo img {
  margin-top: 6%;
  margin-left: 40px;
}

.navbar .navbar_links {
  margin-top: 1%;
  padding: 10px;
  margin-left: auto;
  transition: 0.6s;
}

.navbar .navbar_links a {
  text-decoration: none;
  color: #888888;
  margin-inline: 12px;
  font-weight: 500;
}
.navbar .navbar_links a.is_active {
  color: #4a90e2 !important;
  text-decoration: underline !important;
}
.navbar .navbar_links a:hover {
  color: #4a90e2;
  text-decoration: underline;
}
.navbar .navbar_button {
  margin-top: 20px;
  margin-right: 1%;
  background: transparent linear-gradient(109deg, #c471ed 0%, #4a90e2 100%) 0%
    0% no-repeat padding-box;
  border-radius: 7px;
  height: 35px;
}
.navbar .navbar_button button {
  height: 32px;
  color: #888888;
  font-weight: 500;
  border-radius: 7px;
  cursor: pointer;
}

.navbar .translations_buttons {
  margin-top: 2%;
  margin-right: 1%;
}

.navbar .translations_buttons a {
  margin-right: 5px;
}
.navbar .translations_buttons a img:hover {
  opacity: 0.4;
}

/* Footer */
.footer {
  display: grid;
  grid-template-rows: 80% 20%;
  background: #f9f9f9;
  height: 85px;
  z-index: 10;
}

.footer_first_section {
  display: grid;
  grid-template-columns: 10% 70% auto;
}

.footer .footer_links {
  margin-top: 2%;
  text-align: center;
}

.footer .footer_links a {
  margin-inline: 10px;
  text-decoration: none;
  color: #888888;
  font-weight: 400;
}

.footer .footer_links a:hover {
  font-weight: 500;
}

.footer .footer_socials {
  display: flex;
  margin-top: 7%;
}

.footer .footer_socials a {
  margin-inline: 5px;
  text-decoration: none;
  color: #4a90e2;
}
.footer .footer_socials a:hover {
  color: #306198;
}

.footer .footer_contact button.footer_contact_button {
  border: 2px solid #4a90e2;
  color: #4a90e2;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.footer .footer_website_reference {
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  color: #888888;
  font-size: 12px;
}

/* Dropdown */
.dropdown_container {
  position: absolute;
  z-index: 15;
  width: 150px;
  height: 120px;
  top: 7%;
  left: 65%;
  background: transparent linear-gradient(356deg, #4a90e2 0%, #c471ed 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 4px 8px 36px #0000001f;
  border-radius: 10px;
  opacity: 1;
}

.dropdown_content {
  width: 141px;
  height: 111px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 8px 36px #0000001f;
  border: 2px solid #222222;
  border-radius: 10px;
  opacity: 1;
}
.dropdown_content p {
  margin-top: 3%;
  margin-left: 4%;
}
.dropdown_content p a {
  text-decoration: none;
  color: #888888;
}

.dropdown_content p a:hover {
  color: #3c3b3b;
}

/* Modal de creation de compte */
.modal_background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 17;
  padding-top: 10%;
}

.modal_container {
  display: grid;
  grid-template-rows: 10% auto;
  max-width: 600px;
  margin: 0 auto;
  padding: 5px 20px;
  background: white;
  border-radius: 7px;
  text-align: center;
}

.modal_close button {
  float: right;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: red;
  color: white;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.modal_close button:hover {
  background: rgb(161, 1, 1);
}

/* Content structure */

.app_content,
.global_container,
.global_container_content {
  width: 100%;
  box-sizing: border-box;
}

.app_content {
  height: 550px;
}

.global_container_content {
  grid-template-rows: auto;
}

/* products page */
.product_wrapper {
  grid-row-start: auto;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 50% 50%;
}

.product_wrapper.bg-dark {
  background: transparent linear-gradient(90deg, #f9f9f9 0%, #cecece00 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}

.product_wrapper .product_wrapper_left {
  grid-column-start: 1;
  grid-column-end: 1;
  padding-top: 10%;
  padding-left: 10%;
}

h1.wrapper_title {
  font-size: 60px;
}

p.wrapper_text {
  color: #888888;
  font-size: 26px;
  margin-top: 2%;
}

.product_wrapper_left .wrapper_buttons {
  margin-top: 3%;
  display: grid;
  grid-template-columns: -webkit-max-content;
  grid-template-columns: max-content;
}

.wrapper_buttons .wrapper_button_ios {
  grid-column-start: 1;
  grid-column-end: 1;
  width: 100%;
}

.wrapper_button_ios a img.ios {
  margin-right: 10px;
  max-width: 162px;
}

.wrapper_buttons .wrapper_button_google {
  grid-column-start: 2;
  grid-column-end: 3;
  width: 100%;
}

.wrapper_button_google a img.google {
  width: 162px;
}

.product_wrapper .product_wrapper_right {
  padding-left: 25%;
}
.product_wrapper .product_wrapper_right.pay {
  padding-left: 10%;
}

.product_wrapper_right img {
  max-height: 520px;
}

.crypto_doc_wrapper {
  padding-top: 7%;
  text-align: center !important;
  width: 100%;
}
.crypto_doc_wrapper .crypto_doc_first_section {
  text-align: center;
}

.crypto_doc_wrapper .crypto_doc_first_section h1 {
  font-size: 60px;
  font-weight: 700;
  color: #222222;
}

.crypto_doc_wrapper .crypto_doc_first_section p {
  color: #888888;
  font-size: 26px;
  margin-top: 1%;
  margin-bottom: 2%;
}
.crypto_doc_first_section .button_container {
  width: 336px;
  height: 54px;
  background: transparent linear-gradient(109deg, #c471ed 0%, #4a90e2 100%) 0%
    0% no-repeat padding-box;
  border-radius: 7px;
  margin-left: auto;
  margin-right: auto;
}
.crypto_doc_first_section .button_container button.crypto_doc_link_create {
  text-decoration: none;
  color: #222222;
  padding: 7px;
  border-radius: 7px;
  font-size: 20px;
  width: 336px;
  height: 50px;
  cursor: pointer;
}

.crypto_doc_wrapper .crypto_doc_buttons {
  display: flex;
  margin-top: 2%;
  /* margin-left: 39%; */
}

.crypto_doc_buttons .crypto_doc_buttons_off {
  margin-right: auto;
  margin-left: auto;
}
.crypto_doc_buttons .crypto_doc_buttons_off img.ios {
  margin-right: 5px;
}
.crypto_doc_buttons .crypto_doc_buttons_off img.ios,
.crypto_doc_buttons .crypto_doc_buttons_off img.google {
  max-width: 162px;
}

.transfer_wrapper {
  height: 600px;
  display: grid;
  grid-template-columns: 50% 50%;
}

.transfer_wrapper_left {
  height: 550px;
  display: grid;
  grid-template-rows: 50% 50%;
}
.transfer_wrapper_left_first_section {
  display: grid;
  grid-template-columns: 20% auto;
}
.transfer_wrapper_left_first_section img {
  grid-column-start: 2;
  max-width: 450px;
  margin-top: 2%;
  box-shadow: 0px 12px 10px #000000af;
}
.transfer_wrapper_left_second_section {
  padding-left: 2%;
}
.transfer_wrapper_left_second_section h1,
.transfer_wrapper_right_first_section h1 {
  color: #222222;
  font-size: 72px;
}
.transfer_wrapper_left_second_section p,
.transfer_wrapper_right_first_section p {
  color: #888888;
  font-size: 26px;
}
.transfer_button {
  margin-top: 2%;
  width: 225px;
  height: 54px;
  background: transparent linear-gradient(109deg, #c471ed 0%, #4a90e2 100%) 0%
    0% no-repeat padding-box;
  border-radius: 7px;
}
.transfer_button button.transfer_button_learn_more {
  text-decoration: none;
  color: #222222;
  padding: 7px;
  border-radius: 7px;
  font-size: 20px;
  width: 225px;
  height: 50px;
  cursor: pointer;
}
.transfer_wrapper_right {
  height: 550px;
  display: grid;
  grid-template-rows: 40% auto;
  padding-left: 5%;
}
.transfer_wrapper_right .transfer_wrapper_right_second_section img {
  max-width: 450px;
  box-shadow: 0px 12px 10px #000000af;
}

/* Dev part */
.wrapper_links {
  margin-top: 2%;
}
.wrapper_links button.api_doc_button {
  width: 340px;
  height: 54px;
  border: 2px solid #4a90e2;
  border-radius: 7px;
  opacity: 1;
  background: #fff;
  color: #4a90e2;
  cursor: pointer;
}

/* Pricing part */
.pricing_wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: 20% 60% 20%;
}

.pricing_content {
  margin-top: 7%;
  width: 900px;
  height: 400px;
  background: #f9f9f9;
  border-radius: 25px;
  padding-bottom: 1%;
  display: grid;
  grid-template-columns: 50% 50%;
  box-shadow: 0px 8px 10px #00000026;
  margin-left: auto;
  margin-right: auto;
}

.pricing_content_left {
  margin-top: 2%;
  margin-left: 4%;
}
.pricing_content_left h1 {
  color: #222222;
  font-size: 50px;
  margin-top: 2%;
}
.pricing_content_left p {
  margin-top: 6%;
  color: #888888;
  font-size: 26px;
}

.pricing_button_get_now {
  margin-top: 4%;
  text-align: center;
  width: 336px;
  height: 54px;
  background: transparent linear-gradient(109deg, #c471ed 0%, #4a90e2 100%) 0%
    0% no-repeat padding-box;
  border-radius: 7px;
}

button.get_now_button {
  text-decoration: none;
  color: #222222;
  padding: 7px;
  border-radius: 7px;
  font-size: 20px;
  width: 336px;
  height: 50px;
  cursor: pointer;
}

.pricing_content_right img {
  padding-top: 6%;
  padding-right: 6%;
}

.pricing_offer_content {
  display: grid;
  grid-template-columns: 49% auto 49%;
  margin-top: 5%;
}

.offer_content_left,
.offer_content_right {
  background: #f9f9f9;
  border-radius: 25px;
  padding-top: 2%;
  padding-left: 4%;
  height: 485px;
  box-shadow: 0px 8px 10px #00000026;
}

.offer_content_left small,
.offer_content_right small {
  color: #888888;
  font-weight: 700;
}
.offer_content_left h2,
.offer_content_right h2 {
  margin-top: 4%;
  color: #000000;
  font-size: 40px;
}
.offer_content_left ul,
.offer_content_right ul {
  color: #888888;
  line-height: 30px;
  font-weight: 500;
}

.offer_content_left .offer_button_container,
.offer_content_right .offer_button_container {
  margin-top: 4%;
  text-align: center;
  width: 300px;
  height: 54px;
  background: transparent linear-gradient(109deg, #c471ed 0%, #4a90e2 100%) 0%
    0% no-repeat padding-box;
  border-radius: 7px;
}
.offer_button_container button.offer_button,
.offer_button_container button.offer_button {
  text-decoration: none;
  color: #222222;
  padding: 7px;
  border-radius: 7px;
  font-size: 20px;
  width: 300px;
  height: 50px;
  cursor: pointer;
}

.retailer_wrapper {
  display: flex;
  width: 100%;
  padding: 0 3% 0;
}

.retailer_wrapper_left {
  padding-top: 3%;
  width: 40%;
}

.retailer_wrapper_right {
  padding: 3%;
  max-width: 100%;
  max-height: 100%;
}

/* Package FullPage */

.fp-tableCell {
  display: flex !important;
}

#fp-nav {
  top: 30% !important;
}

/*
*
*
*
*
*
*
*
*
*
*
*/

@media screen and (max-width: 640px) {
  .fp-enabled body,
  html.fp-enabled {
    overflow: auto !important;
  }
  .App,
  .overlay_background {
    display: block;
  }
  .app_content,
  .mobile_view {
    display: block !important;
  }
  .app_content {
    height: 800px;
  }
  .navbar,
  .footer,
  .web_view,
  #fp-nav ul,
  .fp-slidesNav ul {
    display: none;
  }

  .mobile_app_content {
    display: block;
    margin-top: 20px;
  }

  /* Menu Overlay */

  .overlay_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 55%;
    height: 100%;
    background: rgba(0, 0, 0, 0.875);
    z-index: 18;
  }

  .overlay_container {
    display: grid;
    grid-template-rows: 30% auto 20%;
  }
  .overlay_close {
    width: 100%;
  }
  .overlay_close button {
    float: right;
    background: rgb(198, 2, 2);
    border-radius: 50%;
    color: white;
    font-weight: 700;
    border: none;
    margin-right: 5%;
    margin-top: 5%;
    height: 30px;
    width: 30px;
  }
  .overlay_content {
    margin-left: 10%;
  }
  .overlay_content p {
    line-height: 30px;
  }
  .overlay_content a {
    text-decoration: none;
    color: white;
    font-weight: 700;
  }

  .overlay_button {
    margin-top: 10%;
    margin-left: 10%;
    background: transparent linear-gradient(109deg, #c471ed 0%, #4a90e2 100%) 0%
      0% no-repeat padding-box;
    border-radius: 7px;
    height: 35px;
    width: 150px;
  }

  .overlay_button button {
    height: 32px;
    width: 147px;
    border-radius: 7px;
    border: none;
    text-align: center;
    font-weight: 700;
  }

  /* Carousel */
  .carousel .slide {
    text-align: left !important;
    text-align: initial !important;
  }

  .carousel .slide img {
    width: auto !important;
  }

  .carousel img {
    width: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none;
  }

  .carousel .thumbs-wrapper {
    display: none;
  }

  .carousel .carousel-status {
    display: none !important;
  }

  .carousel .control-dots {
    width: auto !important;
    top: 45% !important;
  }

  .carousel .control-dots .dot {
    display: block !important;
    margin: 10px 8px !important;
    background: #000 !important;
  }

  /* Barre de navigation mobile */
  .mobile_navbar {
    display: grid;
    grid-template-columns: 20% 60% auto;
    background: #f9f9f9 !important;
    height: 60px;
  }
  .mobile_navbar_menu_button_container {
    margin-top: 20%;
    margin-right: auto;
    margin-left: auto;
  }
  .mobile_navbar_logo {
    margin-right: auto;
    margin-left: 25%;
    margin-top: 5%;
  }
  .mobile_navbar_logo img {
    max-height: 30px;
  }
  .mobile_navbar_menu_button_container {
    width: 32px;
    height: 34px;
    background: transparent linear-gradient(180deg, #4a90e2 0%, #c471ed 100%) 0%
      0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
  }
  .mobile_navbar_menu_button_container button {
    width: 30px;
    height: 32px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #000000;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;
  }

  .mobile_navbar .translations_buttons {
    margin-top: 25%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .mobile_navbar .translations_buttons a {
    margin-right: 7%;
  }

  /*  Footer mobile */
  .mobile_footer {
    background: #f9f9f9;
    display: grid;
    width: 100% !important;
    bottom: 0 !important;
    position: absolute;
  }
  .mobile_footer .img img {
    margin-left: 2%;
    margin-top: 2%;
    max-height: 20px;
  }
  .mobile_footer .links p a {
    text-decoration: none;
    color: #888888;
    font-weight: 500;
  }
  .mobile_footer .contacts {
    margin-left: 2%;
    margin-top: 2%;
  }
  .mobile_footer .contacts button {
    margin-left: 40%;
    border: 2px solid #4a90e2;
    border-radius: 5px;
    opacity: 1;
    color: #4a90e2;
    background: #fff;
    height: 30px;
  }
  .mobile_footer .contacts a {
    text-decoration: none;
    color: #4a90e2;
    padding-right: 5%;
  }
  .footer_copyright {
    width: 100%;
    margin-top: 2%;
    text-align: center;
    color: #888888;
    padding: 4px;
    font-weight: 500;
    font-size: 14px;
  }

  /* Produit mobile */
  .mobile_product_wrapper {
    display: block;
    height: 100%;
  }
  .mobile_product_wrapper .notabank {
    display: block;
  }
  .mobile_product_wrapper .sendmoney {
    background: #f4f4f4;
    display: block;
  }
  .mobile_product_wrapper .notabank .first {
    padding: 2%;
  }
  .mobile_product_wrapper .sendmoney .first {
    padding: 4%;
  }
  .mobile_product_wrapper .notabank .second,
  .mobile_product_wrapper .sendmoney .second,
  .mobile_product_wrapper .crypto .second {
    text-align: center;
  }
  .mobile_product_wrapper .transfer .first {
    padding: 4%;
  }
  .mobile_product_wrapper .transfer .second {
    padding: 1%;
  }

  .mobile_product_wrapper .crypto {
    display: block;
    background: #f4f4f4;
    height: 100%;
  }
  .mobile_product_wrapper .crypto .first,
  .mobile_product_wrapper .crypto_doc .first {
    padding: 2%;
  }

  .mobile_product_wrapper .crypto_doc {
    display: block;
  }

  .mobile_product_wrapper .transfer .first .transfer_button,
  .mobile_product_wrapper .transfer .second .transfer_button {
    width: 200px;
    height: 38px;
    background: transparent linear-gradient(115deg, #c471ed 0%, #4a90e2 100%) 0%
      0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
  }

  .mobile_product_wrapper .transfer .first .transfer_button button,
  .mobile_product_wrapper .transfer .second .transfer_button button {
    width: 198px;
    height: 35px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #222222;
    border-radius: 7px;
    opacity: 1;
  }
  .mobile_product_wrapper .crypto_doc .first .crypto_doc_button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
    width: 300px;
    height: 38px;
    background: transparent linear-gradient(115deg, #c471ed 0%, #4a90e2 100%) 0%
      0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
  }
  .mobile_product_wrapper .crypto_doc .first .crypto_doc_button button {
    width: 296px;
    height: 34px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #222222;
    border-radius: 7px;
    opacity: 1;
  }

  .mobile_product_wrapper .crypto_doc .second .crypto_doc_links {
    text-align: center;
  }
  .mobile_product_wrapper .crypto_doc .second .crypto_doc_links a img {
    max-width: 120px;
  }

  /* developpers mobile */
  .mobile_dev_wrapper {
    display: block;
  }
  .default_payment {
    display: block;
    height: 100%;
  }
  .default_payment.bg {
    background: #f4f4f4;
    height: 100%;
  }
  .default_payment .first {
    padding: 4%;
  }
  .default_payment .second {
    text-align: center;
  }

  .default_button {
    margin-top: 4%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .default_button_wordpress {
    margin-top: 6%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background: transparent linear-gradient(107deg, #c471ed 0%, #4a90e2 100%) 0%
      0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
    width: 250px;
    height: 40px;
  }
  .default_button_wordpress button,
  .mobile_wallet .get_now_button button {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #222222;
    border-radius: 7px;
    opacity: 1;
    width: 246px;
    height: 36px;
  }
  .default_button button.api {
    width: 250px;
    height: 45px;
    border: 2px solid #4a90e2;
    border-radius: 7px;
    opacity: 1;
    background: #fff;
  }

  /* pricing mobile */
  .mobile_pricing_wrapper,
  .mobile_wallet,
  .mobile_wallet .first {
    display: block;
  }
  .mobile_wallet {
    margin-top: 4%;
  }
  .mobile_wallet .first {
    padding: 4%;
  }
  .mobile_wallet .card_first {
    text-align: center;
  }
  .mobile_wallet .get_now_button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
    text-align: center;
    background: transparent linear-gradient(107deg, #c471ed 0%, #4a90e2 100%) 0%
      0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
    width: 250px;
    height: 40px;
  }

  .mobile_offer_basic {
    margin-left: auto;
    margin-right: auto;
    margin-top: 8%;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 24px #00000026;
    border-radius: 7px;
    opacity: 1;
    width: 329px;
    height: auto;
    padding-bottom: 5%;
  }
  .mobile_offer_basic .button_account,
  .mobile_offer_premium .button_account {
    margin-left: auto;
    margin-right: auto;
    margin-top: 7%;
    width: 270px;
    height: 40px;
    background: transparent linear-gradient(111deg, #c471ed 0%, #4a90e2 100%) 0%
      0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
  }

  .mobile_offer_basic .button_account button,
  .mobile_offer_premium .button_account button {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #222222;
    border-radius: 7px;
    opacity: 1;
    width: 267px;
    height: 37px;
  }

  .mobile_offer_premium {
    margin-left: auto;
    margin-right: auto;
    width: 329px;
    height: auto;
    background: transparent linear-gradient(180deg, #4a90e2 0%, #c471ed 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 8px 24px #00000026;
    border-radius: 7px;
    opacity: 1;
    padding-bottom: 5%;
  }
}
/*
*
*
*
*
*
*
*/

@media only screen and (max-width: 767px) {
  .navbar,
  .footer {
    display: none;
  }
}
@media only screen and (max-width: 1025px) {
  .product_wrapper .product_wrapper_left {
    padding-left: 4%;
  }
  .product_wrapper .product_wrapper_right {
    padding-left: 2%;
  }

  .transfer_wrapper_left_first_section img {
    grid-column-start: 1;
  }
  .transfer_wrapper_right {
    padding-left: 1px;
  }
  .product_wrapper .product_wrapper_right.pay {
    padding-left: 0;
  }
  .product_wrapper_right img.img_resp {
    max-height: 320px;
  }
  .pricing_content_right img {
    width: 100%;
  }
  .pricing_content_left p {
    font-size: 20px;
  }
  .pricing_content_left h1 {
    font-size: 45px;
  }
  .offer_content_left .offer_button_container,
  .offer_content_right .offer_button_container {
    width: 250px;
    height: 40px;
  }
  .offer_button_container button.offer_button,
  .offer_button_container button.offer_button {
    width: 100%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1620px) {
  h1.wrapper_title {
    font-size: 64px;
  }

  .app_content {
    height: 510px;
  }
}

/* Très grands écrans */
@media screen and (min-width: 1900px) and (max-width: 6000px) {
  .app_content {
    height: 700px;
  }

  h1.wrapper_title {
    font-size: 72px;
  }

  p.wrapper_text {
    color: #888888;
    font-size: 26px;
    margin-top: 2%;
  }

  .wrapper_button_ios a img.ios {
    margin-right: 10px;
    max-width: 250px;
  }

  .wrapper_button_google a img.google {
    width: 250px;
  }

  .product_wrapper .product_wrapper_right {
    padding-left: 20%;
  }

  .product_wrapper_right img {
    margin-top: 0;
    max-height: 650px;
    height: 650px !important;
  }

  .product_wrapper_right.resp {
    margin-top: 2px !important;
  }

  .product_wrapper_right.resp img {
    height: 450px !important;
  }

  .product_wrapper_right.resp {
    margin-top: 15% !important;
  }
  .product_wrapper_right.resp.sales {
    margin-left: 22% !important;
  }
  .product_wrapper_right.pay.resp {
    margin-left: 22% !important;
  }

  .transfer_wrapper {
    height: 850px;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .transfer_wrapper_left {
    height: 850px;
    display: grid;
    grid-template-rows: 50% 50%;
  }
  .transfer_wrapper_left_first_section {
    display: grid;
    grid-template-columns: 0;
  }
  .transfer_wrapper_left_second_section h1,
  .transfer_wrapper_right_first_section h1 {
    color: #222222;
    font-size: 102px;
  }
  .transfer_wrapper_left_second_section p,
  .transfer_wrapper_right_first_section p {
    color: #888888;
    font-size: 46px;
  }
  .transfer_wrapper_left_first_section {
    display: grid;
    grid-template-columns: 20% auto;
  }
  .transfer_wrapper_left_first_section img {
    grid-column-start: 2;
    max-width: 550px;
    margin-top: 2%;
    box-shadow: 0px 12px 10px #000000af;
  }
  .transfer_button {
    width: 320px;
    height: 70px;
  }

  .transfer_wrapper_right {
    height: 850px;
    display: grid;
    grid-template-rows: 40% auto;
    padding-left: 0;
  }

  .transfer_wrapper_right .transfer_wrapper_right_second_section img {
    max-width: 550px;
    box-shadow: 0px 12px 10px #000000af;
    margin-left: 10%;
  }

  .transfer_button button.transfer_button_learn_more {
    width: 318px;
    height: 68px;
  }

  .crypto_doc_wrapper {
    width: 100%;
    padding-top: 3%;
    text-align: center;
  }
  .crypto_doc_first_section {
    text-align: center !important;
  }
  .crypto_doc_wrapper .crypto_doc_first_section h1 {
    font-size: 72px;
    font-weight: 700;
    color: #222222;
  }

  .crypto_doc_wrapper .crypto_doc_first_section p {
    color: #888888;
    font-size: 26px;
    margin-top: 1%;
    margin-bottom: 2%;
  }

  .crypto_doc_first_section .button_container {
    width: 450px;
    height: 70px;
  }
  .crypto_doc_first_section .button_container button.crypto_doc_link_create {
    width: 448px;
    height: 68px;
  }

  .crypto_doc_buttons .crypto_doc_buttons_off img.ios,
  .crypto_doc_buttons .crypto_doc_buttons_off img.google {
    max-width: 250px;
  }

  .product_wrapper .product_wrapper_right.pay {
    padding-left: 0;
  }

  .crypto_doc_wrapper.wordpress_cont {
    height: 850px !important;
  }

  .wrapper_links button.api_doc_button {
    width: 450px;
    height: 60px;
  }

  .pricing_content {
    margin-top: 13%;
    height: 400px;
  }

  .pricing_offer_content {
    margin-top: 20%;
  }

  .pricing_offer_content {
    margin-top: 10%;
  }
  .offer_content_left,
  .offer_content_right {
    padding-top: 0;
  }

  .navbar .navbar_logo img,
  .footer .footer_logo img {
    max-height: 25px;
    max-width: 110px;
    padding: 10px;
  }
  .navbar .navbar_logo img {
    margin-top: 25%;
    margin-left: 150%;
  }

  .navbar .navbar_links {
    margin-top: 1%;
  }

  .navbar .navbar_links a {
    margin-inline: 15px;
    font-size: 20px;
  }
  .dropdown_container {
    left: 70%;
  }
}

/**  Ecran moyen (MacBook) **/

@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .navbar .navbar_links a {
    margin-right: 15px;
  }

  .footer .footer_links a {
    margin-right: 15px;
  }
  .footer .footer_socials a {
    margin-right: 5px;
  }

  .app_content {
    height: 512px;
  }

  .product_wrapper .product_wrapper_right {
    padding-left: 1% !important;
  }

  .pricing_offer_content {
    margin-top: 2%;
  }

  .pricing_content_left h1 {
    font-size: 35px;
  }
  .pricing_content_left p {
    font-size: 25px;
  }

  .offer_content_right .offer_button_container,
  .offer_content_left .offer_button_container {
    width: 250px;
    height: 45px;
  }

  .offer_button_container button.offer_button,
  .offer_button_container button.offer_button {
    width: 248px;
    height: 42px;
  }

  .dropdown_container {
    left: 55%;
  }
}

/**  Ecran moyen (MacBook aggrandissement) **/
@media screen and (min-width: 1266px) and (max-width: 1365px) {
  .app_content {
    height: 600px;
  }

  .pricing_offer_content {
    margin-top: 5%;
  }

  .pricing_content_right img {
    padding-top: 6%;
    width: 370px !important;
  }

  .dropdown_container {
    left: 60%;
  }
}

@media screen and (min-width: 1365px){
  .footer {
    height: 150px !important;
  }
}


